.how-it-works-p {
  color: gray;
  font-weight: 200;
}

.date-picker-icon {
  background-color: #e87a28 !important;
}

.rental-exps-icon {
  max-width: 20%;
  max-height: auto;
  border: 1px solid #e87a28;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  background-color: #80808012;
}

.rental-exps-heading {
  color: #e87a28;
  padding: 30px 1px 20px 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.rental-exps-icon-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.rental-exps-detail {
  opacity: 0.7;
}

.rental-exps-sub-headings,
.stepper-comp-headings {
  letter-spacing: 0.1px;
  padding: 5px 0px;
  font-weight: bold;
}

.home-page-rental-exps-container {
  text-align: center;
}

.divider {
  position: absolute;
  background-color: #e87a28;
  z-index: 0;
  /* height: 2px; */
}

@media screen and (min-width: 1400px) {
  .divider {
    top: 30%;
    max-width: calc(100% - 84px);
    height: 2px;
    transform: translateY(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    left: 19%;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .divider {
    top: 30%;
    max-width: calc(100% - 72px);
    height: 2px;
    transform: translateY(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    left: 19%;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .divider {
    top: 22%;
    max-width: calc(100% - 58px);
    height: 2px;
    transform: translateY(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    left: 18%;
    right: 0;
    transform: translateX(50%) translateY(-50%);
  }
}

@media screen and (max-width: 991px) {
  .divider {
    left: 9%;
    max-height: calc(100% - 62px);
    width: 2px;
    transform: translateX(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    top: 42%;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
  }

  .search-box-search-button {
    align-content: flex-end;
  }

  .rental-exps-icon {
    max-width: 9%;
    max-height: fit-content;
    border: 1px solid #e87a28;
    padding: 10px;
    margin: 10px 20px;
  }

  .rental-exps-icon-text-container {
    flex-direction: row;
    text-align: left;
    padding-left: 10px;
    padding-bottom: 30px;
    align-items: center;
  }

  .rental-exps-sub-headings,
  .stepper-comp-headings {
    letter-spacing: 0.1px;
    padding: 5px 13px;
  }
}

@media screen and (max-width: 767px) {
  .divider {
    left: 11%;
    max-height: calc(100% - 57px);
    width: 2px;
    transform: translateX(-50%);
  }

  .rental-exps-icon {
    max-width: 11%;
    max-height: fit-content;
    border: 1px solid #e87a28;
    padding: 10px;
    margin: 10px 20px;
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    top: 38%;
    bottom: 0;
    transform: translateX(-50%) translateY(50%);
  }
}

@media screen and (max-width: 520px) {
  .divider {
    left: 11.3%;
    max-height: calc(100% - 54px);
    width: 2px;
    transform: translateX(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    top: 38%;
  }

  .rental-exps-detail {
    font-size: small;
  }
}

@media screen and (max-width: 430px) {
  .rental-exps-sub-headings,
  .stepper-comp-headings {
    font-size: 16px;
  }
  .bg-img-banner-main {
    position: relative;
  }

  .rental-exps-icon {
    max-width: 14%;
  }

  .rental-exps-detail {
    font-size: smaller;
  }

  .divider {
    left: 14%;
    max-height: calc(100% - 53px);
    width: 2px;
    transform: translateX(-50%);
  }

  .rental-exps-icon-text-container:not(:last-child) .divider {
    top: 37%;
  }
}

@media screen and (max-width: 375px) {
  .rental-exps-icon {
    max-width: 18%;
  }

  .divider {
    left: 17.5%;
    max-height: calc(100% - 60px);
  }
  .rental-exps-icon-text-container:not(:last-child) .divider {
    top: 40%;
  }
}
