/*font Variables*/
/*Color Variables*/
@import url("https://fonts.googleapis.com/css?family=Roboto:300i,400,400i,500,700,900");

.progress-bar-div {
  padding: 40px 80px;
  padding-bottom: 1px;
}

/* Progress bar Css  */

.multi_step_form {
  display: block;
  overflow-x: hidden;
}
.multi_step_form #msform {
  position: relative;
  z-index: 1;
}
.multi_step_form #msform .tittle {
  text-align: center;
  padding-bottom: 55px;
}
.multi_step_form #msform .tittle h2 {
  font: 500 24px/35px "Roboto", sans-serif;
  color: #3f4553;
  padding-bottom: 5px;
}
.multi_step_form #msform .tittle p {
  font: 400 16px/28px "Roboto", sans-serif;
  color: #5f6771;
}
.multi_step_form #msform fieldset {
  border: 0;
  padding: 20px 105px 0;
  position: relative;
  width: 100%;
  left: 0;
  right: 0;
}
.multi_step_form #msform fieldset:not(:first-of-type) {
  display: none;
}
.multi_step_form #msform fieldset h3 {
  font: 500 18px/35px "Roboto", sans-serif;
  color: #3f4553;
}
.multi_step_form #msform fieldset h6 {
  font: 400 15px/28px "Roboto", sans-serif;
  color: #5f6771;
  padding-bottom: 30px;
}
.multi_step_form #msform fieldset .intl-tel-input {
  display: block;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
}
.multi_step_form
  #msform
  fieldset
  .intl-tel-input
  .flag-container
  .selected-flag {
  padding: 0 20px;
  background: transparent;
  border: 0;
  box-shadow: none;
  outline: none;
  width: 65px;
}
.multi_step_form
  #msform
  fieldset
  .intl-tel-input
  .flag-container
  .selected-flag
  .iti-arrow {
  border: 0;
}
.multi_step_form
  #msform
  fieldset
  .intl-tel-input
  .flag-container
  .selected-flag
  .iti-arrow:after {
  content: "\f35f";
  position: absolute;
  top: 0;
  right: 0;
  font: normal normal normal 24px/7px Ionicons;
  color: #5f6771;
}
.multi_step_form #msform fieldset #phone {
  padding-left: 80px;
}
.multi_step_form #msform fieldset {
  padding: 0 10px;
}
.multi_step_form #msform fieldset .fg_2,
.multi_step_form #msform fieldset .fg_3 {
  padding-top: 10px;
  display: block;
  overflow: hidden;
}
.multi_step_form #msform fieldset .fg_3 {
  padding-bottom: 70px;
}
.multi_step_form #msform fieldset .form-control,
.multi_step_form #msform fieldset {
  border-radius: 3px;
  border: 1px solid #d8e1e7;
  padding: 0 20px;
  height: auto;
  font: 400 15px/48px "Roboto", sans-serif;
  color: #5f6771;
  box-shadow: none;
  outline: none;
  width: 100%;
}

.multi_step_form #msform fieldset {
  padding-top: 40px;
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  margin-bottom: 10px;
  background: #e87a28;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
}

.multi_step_form #msform fieldset {
  margin-bottom: 60px;
}
.multi_step_form #msform fieldset .form-control {
  border: 0;
  border-bottom: 1px solid #a1a7ac;
  border-radius: 0;
  display: inline-block;
  width: 30px;
  font-size: 30px;
  color: #5f6771;
  padding: 0;
  margin-right: 7px;
  text-align: center;
  line-height: 1;
}

.multi_step_form #msform fieldset {
  height: 36px;
  width: 36px;
  line-height: 36px;
  font-size: 22px;
  position: absolute;
  top: 4px;
  right: 0;
  background: #e87a28;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  margin-top: -10px;
  padding-bottom: 30px;
  position: relative;
}
.multi_step_form #msform fieldset h4 {
  font: 500 15px/23px "Roboto", sans-serif;
  color: #5f6771;
  padding: 0;
}

.multi_step_form #msform #progressbar {
  overflow: hidden;
}
.multi_step_form #msform #progressbar li {
  list-style-type: none;
  color: rgb(82, 82, 82);
  font-weight: 500;
  width: calc(100% / 3);
  float: left;
  position: relative;
}
.multi_step_form #msform #progressbar li:nth-child(2):before {
  content: "\f12e";
}
.multi_step_form #msform #progressbar li:nth-child(3):before {
  content: "\f119";
}
.multi_step_form #msform #progressbar li:before {
  content: "\f36f";
  font: normal normal normal 30px/50px Ionicons;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  background: white;
  border: 1px solid gray;
  border-radius: 50%;
  margin: 0 auto 10px auto;
}
.multi_step_form #msform #progressbar li:after {
  content: "";
  width: 100%;
  height: 5px;
  background: gray;
  position: absolute;
  left: -50%;
  top: 21px;
  z-index: -1;
}
.multi_step_form #msform #progressbar li:last-child:after {
  width: 150%;
}
.multi_step_form #msform #progressbar li.active {
  color: #e87a28;
}
.multi_step_form #msform #progressbar li.active:before,
.multi_step_form #msform #progressbar li.active:after {
  background: #e87a28;
  color: white;
  border: none;
}

.intro {
  background: #fff;
  padding: 60px 30px;
  color: #333;
  margin-bottom: 15px;
  line-height: 1.5;
  text-align: center;
}
.intro h1 {
  font-size: 18pt;
  padding-bottom: 15px;
}
.intro p {
  font-size: 14px;
}

.action {
  text-align: center;
  display: block;
  margin-top: 20px;
}

a.btn {
  text-decoration: none;
  color: #666;
  border: 2px solid #666;
  padding: 10px 15px;
  display: inline-block;
  margin-left: 5px;
}
a.btn:hover {
  background: #666;
  color: #fff;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}
.btn:before {
  font-weight: normal;
  margin-right: 10px;
}
.github:before {
  content: "\f09b";
}
.down:before {
  content: "\f019";
}
.back:before {
  content: "\f112";
}
.credit {
  background: #fff;
  padding: 12px;
  font-size: 9pt;
  text-align: center;
  color: #333;
  margin-top: 40px;
}
.credit span:before {
  color: #e41b17;
  content: "\f004";
}
.credit a {
  color: #333;
  text-decoration: none;
}
.credit a:hover {
  color: #1dbf73;
}
.credit a:hover:after {
  content: "\f08e";
  font-size: 9pt;
  position: absolute;
  margin: 3px;
}
main {
  background: #fff;
  padding: 20px;
}

article h1,
article h2,
article h3,
article h4,
article p {
  padding: 14px;
  color: #333;
}
article p {
  font-size: 15px;
  line-height: 1.5;
}

@media only screen and (min-width: 820px) {
  main {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    padding: 24px;
  }
}

.set-overlayer,
.set-glass,
.set-sticky {
  cursor: pointer;
  height: 45px;
  line-height: 45px;
  padding: 0 15px;
  color: #333;
  font-size: 16px;
}
.set-overlayer:after,
.set-glass:after,
.to-active:after,
.set-sticky:after {
  font-size: 18pt;
  position: relative;
  float: right;
}
.set-overlayer:after,
.set-glass:after,
.set-sticky:after {
  content: "\f204";
  transition: 0.6s;
}

.to-active:after {
  content: "\f205";
  color: #008080;
  transition: 0.6s;
}
.set-overlayer,
.set-glass,
.set-sticky,
.source,
.theme-tray {
  margin: 10px;
  background: #f2f2f2;
  border-radius: 5px;
  border: 2px solid #f1f1f1;
  box-sizing: border-box;
}

pre.prettyprint {
  padding: 15px !important;
  margin: 10px;
  border: 0 !important;
  background: #f2f2f2;
  overflow: auto;
}

.source {
  white-space: pre;
  overflow: auto;
  max-height: 400px;
}
code {
  border: 1px solid #ddd;
  padding: 2px;
  border-radius: 2px;
}

.car-key-features-div {
  max-height: 373px;
  overflow-y: visible;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  line-height: 35px;
}

@media screen and (max-width: 991px) {
  .vertical-line-car-details-page {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .progress-bar-div {
    display: none;
  }

  .all-vehicles-features-icons {
    max-height: 373px;
    overflow-y: visible;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .coupon-apply-button-div {
    display: contents;
  }
}

@media screen and (max-width: 576px) {
  .coupon-apply-button-div {
    display: contents;
  }

  .total-days-row {
    padding: 6px 10px;
  }
  .total-days-row span,
  .grand-total-price-label,
  .grand-total-price-value,
  .deleted-grand-total-price-value {
    font-size: 16px;
  }
  .btn.btn-success.apply-coupon-btn {
    margin: 10px;
    padding: 6px 30px;
  }

  .coupon-label-div {
    margin-top: 14px;
  }
  .booing-text {
    font-size: small;
  }

  .deleted-grand-total-price-value:before {
    content: "";
    position: absolute;
    margin-top: 10px;
    width: 40px;
    border-bottom: 2px solid green;
    transform: rotate(-15deg);
    -webkit-transform: rotate(-28deg);
  }

  .coupon-discount-value {
    position: absolute;
    margin: 15px 0px 0px 23px;
    font-size: small;
  }
  .progress-bar-div {
    display: none;
  }
}

.add-on-container {
  border: 1px solid rgba(0, 0, 0, 0.459);
  border-radius: 8px;
  padding: 10px 20px;
  margin: 15px 0px;
  background-color: white;
}

.add-ons-view-details {
  color: black;
  font-weight: 500;
}

.add-ons-view-details:hover {
  color: #e87a28;
  font-weight: 500;
  text-decoration: underline !important;
}

.add-ons-toggle-input:checked {
  background-color: #e87a28;
  border-color: #e87a28;
  box-shadow: none;
}

.add-ons-toggle-input:checked:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
}

.add-ons-toggle-input:focus {
  box-shadow: none;
  border-color: rgb(214, 214, 214);
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
}

.form-check-input {
  clear: left;
  border-color: rgba(0, 0, 0, 0.5);
}

.form-check-input:checked {
  background-color: #e87a28;
  border-color: #e87a28;
}

.form-check-input:focus {
  border-color: #e87a28;
  box-shadow: 0 0 0 0 #e87a28;
}

.form-switch.form-switch-sm {
  margin-bottom: 0.5rem;
}

.form-switch.form-switch-sm .form-check-input {
  height: 1rem;
  width: calc(1rem + 0.75rem);
  border-radius: 2rem;
}

.form-switch.form-switch-md {
  margin-bottom: 1rem;
}

.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: calc(2rem + 0.75rem);
  border-radius: 3rem;
  cursor: pointer;
}

.form-switch.form-switch-lg {
  margin-bottom: 1.5rem;
}

.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: calc(3rem + 0.75rem);
  border-radius: 4rem;
}

.form-switch.form-switch-xl {
  margin-bottom: 2rem;
}

.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: calc(4rem + 0.75rem);
  border-radius: 5rem;
}
