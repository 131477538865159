.bg-img-banner-main {
  overflow: hidden;
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  position: initial;
  background-repeat: no-repeat;
  background-image: url("../../images/no-car-bg-home-banner-new.webp");
}

.each-slide-effect:hover,
.blog-image-container:hover {
  transform: scale(1.02);
  transition: 0.4s ease-in-out;
}

.home-page-heading-underline {
  border: 1px solid #e87a28;
  opacity: 0.8;
}
.home-page-heading-icon {
  padding: 11px;
  border-radius: 6px;
  width: 45px;
  height: 45px;
  background-color: white;
  border: 1px solid rgb(237, 235, 235);
}

.main-banner-img {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

.heading-icon-container-div,
.fleet-car-homePage-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fleet-car-main-section {
  background-color: rgba(174, 174, 174, 0.164);
}
.car-movement {
  overflow: hidden;
  font-size: 40px;
}

.car-movement > .car {
  position: relative;
  display: inline-block;
  animation: linear infinite;
  animation-name: run;
  animation-duration: 15s;
}

.animated-car-banner {
  width: 70%;
  height: 100%;
}

@keyframes run {
  0% {
    transform: rotateY(180deg);
    left: -100px;
  }
  50% {
    transform: rotateY(180deg);
    left: 100%;
  }
  51% {
    transform: rotateY(0deg);
    left: calc(100% + 100px);
  }
  100% {
    transform: rotateY(0deg);
    left: -100px;
  }
}

/* Car Brands Icons CSS  */

.car-brands-slider-container {
  overflow: hidden;
  position: relative;
}

.car-brands-slider {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.car-brands-slider.paused {
  animation-play-state: paused;
}

.car-brand {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 10px;
  padding: 18px;
  color: #e87a28;
  background-color: white;
  margin: 5px 10px;
  transition: transform 0.1s ease-in-out;
}

.car-brand:hover {
  transform: scale(1.1);
}

.brands-logo-images {
  max-width: 80px;
}

.car-brands-icons-container {
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  background-color: white;
}

@keyframes slide {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.leasing-milele-car-heading {
  background-color: rgb(66, 66, 66);
  padding: 20px;
}

.date-label,
.location-label {
  padding: 20px 1px 5px 1px;
}

.login-form-label,
.forgotPassword-form-label,
.update-password-form-label {
  display: flex;
  align-items: center;
}

.slider-item {
  width: 100%;
  height: auto;
  max-height: 400px;
  object-fit: cover;
}

.styled-label {
  color: #e87a28;
}

.search-box-label {
  color: white;
}

.search-box-container {
  border-radius: 30px;
  padding: 10px 60px;
  /* box-shadow: 3px 1px 9px #e87a2861; */
  margin: 10px 20px 20px 20px;
  border: 1px solid #e87a285e;
  background: #40404063;
  backdrop-filter: blur(3px);
}

.search-box-search-button-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;
}

.search-box-search-button,
.rent-now-button {
  border: 1px solid #e87a28;
  border-radius: 10px;
  background-color: #e87a28;
  color: white;
}

.search-box-search-button {
  padding: 10px 15px;
  margin-top: 23px;
}

.rent-now-button {
  padding: 10px;
}

.search-box-search-button:hover,
.rent-now-button:hover {
  color: #e87a28;
  background-color: white;
}

.rental-exp-icon {
  max-width: 20%;
  max-height: auto;
  border: 1px solid #e87a28;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 10px;
  background-color: #80808012;
}

.document-required-home-page-heading,
.car-rental-in-dubai-heading,
.rental-exp-heading
{
  color: #e87a28;
  padding: 30px 1px 20px 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.fleet-page-content {
  color: #e87a28;
  padding: 30px 1px 20px 1px;
  font-weight: 400;
}

.rental-exp-icon-text-container {
  display: block;
}

.rental-exp-sub-headings,
.stepper-comp-headings {
  letter-spacing: 0.1px;
  padding: 5px 0px;
  font-weight: bold;
}

.rent-exp-pic-container {
  display: contents;
}

.react-slideshow-container .nav:first-of-type {
  left: 88% !important;
  top: 102% !important;
}
.react-slideshow-container .default-nav:last-of-type {
  right: 1% !important;
  top: 102% !important;
}

.react-slideshow-container .default-nav:first-of-type {
  border-right: 1px solid #e87a28 !important;
  border-top: 1px solid #e87a28 !important;
}

.react-slideshow-container .default-nav {
  height: 37px !important;
  margin: 5px 5px !important;
  padding: 5px 11px !important;
  width: 37px !important;
  width: 37px;
  border: none;
}

.react-slideshow-container .default-nav:hover {
  height: 37px !important;
  margin: 5px 5px !important;
  padding: 5px 11px !important;
  width: 37px !important;
  background-color: rgba(128, 128, 128, 0.644) !important;
}

.react-slideshow-container .nav {
  color: white !important;
  background-color: #e87a28 !important;
}

.react-slideshow-container .nav:hover {
  background-color: white !important;
  border: 1px solid #e87a28;
}

.react-slideshow-container .nav svg {
  fill: white !important;
}

.react-slideshow-container .nav:hover svg {
  fill: #e87a28 !important;
}

.rental-exp-detail,
.stepper-comp-detail {
  opacity: 0.7;
}

.slick-prev:before,
.slick-next:before {
  color: #e87a28 !important;
  opacity: 1 !important;
  font-size: 35px !important;
  border-radius: 50px;
}

.slick-prev:hover::before,
.slick-next:hover::before {
  color: white !important;
  opacity: 1 !important;
  background-color: #e87a28;
}

.slick-arrow.slick-prev {
  display: block !important ;
  top: 105% !important;
  left: 90% !important;
}

.slick-arrow.slick-next {
  display: block !important ;
  top: 105% !important;
  right: 3% !important;
}

.home-page-rental-exp-container {
  text-align: center;
}

.different-loc-checkbox-div .form-check .form-check-label {
  color: white !important;
}

.all-cars-search-box-container {
  border-radius: 6px;
  padding: 0px !important;
  background-color: white;
  /* box-shadow: 2px 1px 18px #a39f9f; */
}

.total-days {
  color: #e87a28;
  font-weight: 600;
}

.booking-page-banner-navbar {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

.search-box-home-page-container {
  position: relative;
}

@media screen and (min-width: 768px) {
  .search-box-container {
    transform: translate(12px, 90px);
    position: relative;
  }
}

@media screen and (max-width: 1400px) {
  .search-box-home-page {
    position: absolute;
    top: 0%;
    left: 0% !important;
    right: 0% !important;
    z-index: 1;
    pointer-events: auto;
  }
}

@media screen and (max-width: 991px) {
  .search-box-search-button {
    align-content: flex-end;
  }

  .rental-exp-icon {
    max-width: 12%;
    max-height: fit-content;
    border: 1px solid #e87a28;
    padding: 10px;
    margin: 10px 20px;
  }
  .rental-exp-icon-text-container {
    display: flex;
    text-align: left;
    padding-left: 10px;
    padding-bottom: 20px;
    align-items: center;
  }
  .rental-exp-sub-headings,
  .stepper-comp-headings {
    letter-spacing: 0.1px;
    padding: 5px 13px;
  }

  .slick-arrow.slick-prev {
    left: 43% !important;
  }

  .slick-arrow.slick-next {
    right: 43% !important;
  }

  .react-slideshow-container .nav:first-of-type {
    left: 45% !important;
  }
  .react-slideshow-container .default-nav:last-of-type {
    right: 45% !important;
  }
}

@media screen and (max-width: 768px) {
  .bg-img-banner-main {
    position: relative;
    height: 425px;
  }
}

@media screen and (max-width: 576px) {
  .search-box-label {
    font-size: 14px;
  }
}

@media screen and (max-width: 476px) {
  .search-box-label {
    font-size: 14px;
  }
  .search-box-container {
    padding: 10px 20px;
  }
}

@media screen and (max-width: 430px) {
  .bg-img-banner-main {
    position: relative;
  }

  .rental-exp-icon {
    max-width: 17%;
    max-height: fit-content;
    border: 1px solid #e87a28;
    padding: 8px;
    margin: 10px 15px;
  }
  .rental-exps-detail {
    font-size: smaller;
  }

  .rent-now-button {
    margin-top: 8px;
  }
}

.search-box-home-page {
  position: absolute;
  top: 0%;
  left: 10%;
  right: 10%;
  z-index: 1;
  pointer-events: auto;
}

.home-page-horizontal-line-2 {
  padding: 10px 5px 2px 5px;
  background-color: #e87a28;
  text-align: center;
  word-spacing: 1px;
}

.home-page-horizontal-line-2-text {
  color: white;
  font-weight: 400;
  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .main-banner-img {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    object-fit: fill;
    aspect-ratio: 2/1;
  }
  .home-page-horizontal-line-2-text {
    font-size: 25px;
  }
}

.form-control-lto-input {
  border: 1px solid rgb(184, 184, 184);
}

.form-control-location,
.form-control-forgotPassword,
.form-control-login,
.form-control-signup,
.form-control-update-password,
.form-control-coupon,
.form-control-price-filter,
.form-control-consultation,
.form-control-age,
.form-control-lto-input {
  height: 45px;
  border-radius: 6px;
  padding: 10px 10px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}
.form-control-location,
.form-control-forgotPassword,
.form-control-login,
.form-control-signup,
.form-control-update-password,
.form-control-coupon,
.form-control-price-filter,
.form-control-age {
  border: 1px solid rgb(184, 184, 184);
}

.form-control-phone-number {
  height: 45px !important;
  border: 1px solid #e87a28 !important;
  border-radius: 6px;
  padding: 0px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}

.form-control-customer-number {
  height: 45px !important;
  border: 1px solid rgb(184, 184, 184) !important;
  border-radius: 6px;
  padding: 0px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}

.form-control-contact-us-phone-input,
.form-control-lto-userDetails-phone-input {
  height: 45px !important;
  border: 1px solid rgb(184, 184, 184) !important;
  border-radius: 6px;
  padding: 0px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}

.form-control-consultation {
  border: 1px solid #e87a28 !important;
}

.react-tel-input .form-control {
  border: none !important;
  height: 42px !important;
  padding: 0px 0px 0px 48px !important;
  font-weight: 600 !important;
  width: 100% !important;
}

.react-tel-input .flag-dropdown {
  border: none !important;
  border-radius: 5px 0 0 5px !important;
  padding: 1px !important;
  background-color: transparent !important;
}

.react-tel-input .selected-flag {
  padding: 0 0 0 15px !important;
}

.css-wsp0cs-MultiValueGeneric {
  padding: 0px 0px 0px 6px !important;
  color: white !important;
}

.css-1xc3v61-indicatorContainer {
  padding: 1px !important;
}

.css-1p3m7a8-multiValue {
  background-color: #e87a28 !important;
  color: white;
}

.css-3w2yfm-ValueContainer {
  padding: 4px !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: #e87a28 !important;
  color: white !important;
  margin-left: 5px !important;
}

.css-13cymwt-control,
.css-t3ipsp-control,
.css-1nmdiq5-menu {
  border: 1px solid rgb(184, 184, 184) !important;
  line-height: 32px;
  border-radius: 6px;
  /* margin-left: -13px; */
}

.css-t3ipsp-control {
  box-shadow: none !important;
}

.css-b62m3t-container {
  padding: initial !important;
}

.css-t3ipsp-control:active {
  outline: none;
  border-color: none;
  box-shadow: none;
}
.nationality-dropdown .css-1nmdiq5-menu .css-1n6sfyn-MenuList {
  max-height: 170px !important;
}

.css-1n6sfyn-MenuList {
  padding-top: 0px !important;
  cursor: pointer !important;
}

.css-1n6sfyn-MenuList::selection {
  background-color: white !important;
}

.css-tr4s17-option {
  background-color: #e87a28 !important;
}

.css-d7l1ni-option {
  background-color: rgb(234, 234, 234) !important;
}

.css-9jq23d {
  color: white !important;
}

.css-187mznn-MuiSlider-root {
  color: #e87a28 !important;
}

.form-control-consultation:focus {
  outline: 1px solid #e87b286f !important;
}

.form-control-location:focus,
.form-control-forgotPassword:focus,
.form-control-login:focus,
.form-control-signup:focus,
.form-control-update-password:focus,
.form-control-coupon:focus,
.form-control-price-filter:focus,
.form-control-age:focus,
.form-control-lto-input:focus {
  outline: 1px solid rgba(0, 0, 0, 0.246) !important;
}

.form-control-contact-us,
.form-control-customer-profile-details,
.form-control-lto-userDetails {
  border: 1px solid rgb(184, 184, 184) !important;
  border-radius: 6px;
  padding: 10px 10px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}

.contact-us-label h6,
.customer-profile-details-label h6,
.lto-userDetails-label h6 {
  font-weight: 600;
  letter-spacing: 1px;
}

.diff-dropoff-loc-lable label {
  font-weight: 600;
  color: #e87a28;
}

/* Car cards CSS  */

.car-image-container {
  position: relative;
  height: 230px;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
}

.car-image {
  width: 90%;
  object-fit: contain;
  border-radius: 6px;
}

.car-image:hover {
  cursor: pointer;
}

.offer-car-item {
  border: none;
  border-radius: 6px;
  box-shadow: 1px 4px 10px 3px rgba(85, 68, 68, 0.35);
  position: relative;
  background-color: white;
}

.offer-heading,
.testimonial-heading {
  color: #e87a28;
}

.offers-car-container-row {
  display: flex;
  align-items: center;
}

.offers-cars-container {
  display: contents;
}

.banner-cards-images,
.fleet-vehicles-images {
  width: 100%;
  height: auto;
  border-radius: 20px;
}

.fleet-vehicles-images {
  cursor: pointer;
}

.pick-drop-date-icon {
  padding-right: 1px;
}

.single-car-container-div {
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.offer-car-item {
  border: none;
  border-radius: 6px;
  box-shadow: 1px 4px 10px 3px rgba(85, 68, 68, 0.35);
}

.discount-container {
  text-align: center;
  margin-top: 10px;
}

.discount-line {
  width: 100%;
  padding: 0px;
  margin: px 0px;
  border: 2px solid #ba4b01;
}

.discount-percent {
  background-color: white;
  padding: 0 10px;
}

#hr-value-tag-1 {
  transform: rotate(135deg);
  width: 52px;
  position: absolute;
  border-bottom: 2px solid #e87a28;
  height: 100px;
  top: 3px;
  left: 27px;
}

#hr-value-tag-2 {
  transform: rotate(135deg);
  width: 116px;
  position: absolute;
  border-bottom: 2px solid #e87a28;
  height: 100px;
  top: 26px;
  left: 18px;
}

.tilted-car-price {
  position: absolute;
  transform: rotate(-45deg);
  top: 19px;
  left: 0px;
}

/* Animation Car Section  */

html {
  scroll-behavior: smooth;
}

.nav-btn {
  color: #e87a28;
  background: transparent;
  padding: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.nav-btn:hover {
  color: #e87a28 !important;
  background-color: white !important;
  box-shadow: 1px 1px 5px #e87a28;
  padding: 3px 10px 1px 10px !important;
}

.prev-slide {
  left: 10px;
}

.next-slide {
  right: 10px;
}

.owl-carousel .owl-nav.disabled + .owl-dots {
  margin-top: 20px;
}

.slider-container {
  position: relative;
  overflow: hidden;
}

.owl-carousel .owl-stage-outer {
  overflow: visible !important;
}
.owl-carousel .owl-item.active.center .slider-card {
  box-shadow: 1px 1px 16px rgba(186, 186, 186, 0.8);
}

.owl-carousel .owl-item img {
  width: 80% !important;
}

.animated-car-anchor-tag {
  display: contents;
}

.animated-car-anchor-tag:hover {
  cursor: pointer;
}

.slider {
  margin-bottom: 30px;
  margin-top: 30px;
  position: relative;
}
.slider .owl-item.active.center .slider-card {
  transform: scale(1);
  opacity: 1;
}
.slider-card {
  padding: 0px 0px;
  margin: 30px 5px 40px 5px;
  border-radius: 5px;
  transform: scale(0.75);
  opacity: 0.5;
  transition: all 0.3s;
}
.slider-card img {
  border-radius: 5px 5px 0px 0px;
}

.owl-nav .owl-prev {
  position: absolute;
  top: 50%;
  left: 10px;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
  box-shadow: none !important;
}
.owl-nav .owl-next {
  position: absolute;
  top: 50%;
  right: 10px;
  opacity: 1;
  font-size: 30px !important;
  z-index: 1;
  box-shadow: none !important;
}

.owl-dots span {
  display: none !important;
}

.owl-dots .owl-dot.active {
  background: #f44336 !important;
}

.slider-card h2 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 10px;
  color: #e87a28;
  font-weight: 700;
}

.slider-card {
  height: 300px;
  overflow: hidden;
}

.slider-card img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.slider-imgs {
  height: fit-content;
}

/* Testimonial Slider CSS */

.alice-carousel__prev-btn {
  text-align: left !important;
  width: 48% !important;
}

.alice-carousel__next-btn {
  text-align: right !important;
  width: 48.5% !important;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  font-size: 22px !important;
  padding: 5px 12px !important;
  border-radius: 5px !important;
  color: black !important;
  font-weight: 600;
  position: absolute;
  top: 45%;
}

.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: #e87a28 !important;
  background-color: white !important;
  box-shadow: 1px 1px 5px #e87a28;
}

.alice-carousel__prev-btn-item:active,
.alice-carousel__next-btn-item:active {
  border: 1px solid #fcfcfc !important;
  box-shadow: 1px 1px 5px #606060;
}

.alice-carousel__dots-item:not(.__custom):hover,
.alice-carousel__dots-item:not(.__custom).__active {
  background-color: #e87a28 !important;
}

.alice-carousel__dots-item:not(.__custom) {
  border: 1px solid lightgrey !important;
  background-color: white !important;
  width: 12px !important;
  height: 12px !important;
}

.carousel img {
  width: 50% !important;
}

.carousel li.slide img {
  width: 90% !important;
}

.carousel li.thumb img {
  width: 98% !important;
}

.testimonial-main-dv {
  padding: 25px 25px 0px 25px;
}

.custom-dropdown-container {
  position: relative;
}

.custom-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  background-color: #e1e1e1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 8px;
  width: 300%;
}

.custom-dropdown button {
  display: block;
  padding: 8px;
  text-align: left;
  border: none;
  cursor: pointer;
  font-size: small;
}

.btn.btn-success.pick-drop-myself-button {
  margin: 10px 0px;
}

option:disabled {
  color: white !important;
  background-color: #e87a28 !important;
}

.pickup-location-col-container,
.dropoff-location-col-container {
  display: flex;
  align-items: center;
}

.pickup-location-text,
.dropoff-location-text {
  margin-top: 9px;
  padding: 10px;
  border: 1px solid rgb(197, 197, 197);
}

.alice-carousel__prev-btn {
  margin-left: -25px;
}

.alice-carousel__next-btn {
  margin-left: 40px;
}
@media screen and (min-width: 576px) and (max-width: 767px) {
  .custom-dropdown {
    width: 209%;
  }
  .submit-button {
    margin-top: 15px;
  }
  .alice-carousel__prev-btn {
    margin-left: -20px;
  }

  .alice-carousel__next-btn {
    margin-left: 35px;
  }
}
@media screen and (max-width: 991px) {
  .alice-carousel__next-btn {
    text-align: right !important;
    width: 93% !important;
  }
  .react-slideshow-container .nav:first-of-type {
    left: 40% !important;
  }
  .react-slideshow-container .default-nav:last-of-type {
    right: 40% !important;
  }
  .banner-cards-images {
    border-radius: 0px;
  }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
  .custom-dropdown {
    width: 284%;
  }
  .alice-carousel__prev-btn {
    margin-left: -20px;
  }

  .alice-carousel__next-btn {
    margin-left: 30px;
  }
}

@media screen and (max-width: 576px) {
  .custom-dropdown {
    width: 100%;
  }
  .submit-button {
    margin-top: 15px;
  }
  .pickup-locations-button-col,
  .dropoff-locations-button-col {
    display: inline !important;
  }
  .alice-carousel__prev-btn {
    margin-left: -10px;
  }

  .react-slideshow-container .nav:first-of-type {
    left: 35% !important;
  }
  .react-slideshow-container .default-nav:last-of-type {
    right: 35% !important;
  }

  .slick-arrow.slick-prev {
    left: 38% !important;
  }

  .slick-arrow.slick-next {
    right: 38% !important;
  }

  .slider-card {
    padding: 5px 50px 0px 50px;
  }
  .alice-carousel__next-btn {
    text-align: right !important;
    margin-left: -7px;
    width: 85% !important;
  }
}

@media screen and (max-width: 920px) {
  .bg-img-banner-main {
    position: relative;
  }
}

@media screen and (max-width: 425px) {
  .car-image-container {
    height: 160px;
  }
  .main-banner-img {
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    object-fit: contain;
    aspect-ratio: 2/1;
  }
  .home-page-horizontal-line-2-text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1200px) {
  .main-banner-img {
    background-repeat: no-repeat;
    position: relative;
    object-fit: cover;
    aspect-ratio: 2/1;
  }
}
