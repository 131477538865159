.footer-heading {
  font-weight: 600;
  padding-top: 42px;
}

.footer-info-list a {
  text-decoration: none;
  color: black;
}

.footer-info-list a:hover {
  text-decoration: none;
  color: #e87a28;
}

.footer-contact-us {
  left: 5%;
}

.footer-main-container {
  background-color: rgba(242, 242, 242, 0.666);
}

@media screen and (max-width: 1399px) {
  .footer-heading {
    padding-top: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .footer-heading {
    padding-top: 15px;
  }
}

@media screen and (max-width: 991px) {
  .footer-heading {
    padding-top: 28px;
  }
}

@media screen and (max-width: 767px) {
  .footer-contact-us {
    left: 0%;
  }
}


@media screen and (max-width: 430px) {
  .footer-info-paragraph {
    display: none;
  }
}
