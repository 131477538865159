/* @import url(https://fonts.googleapis.com/css?family=Lato:300,400,700|Raleway:400,300,700);
@font-face {
  font-family: "codropsicons";
  src: url("https://tympanus.net/Development/IconHoverEffects/fonts/codropsicons/codropsicons.eot");
  src: url("https://tympanus.net/Development/IconHoverEffects/fonts/codropsicons/codropsicons.eot?#iefix")
      format("embedded-opentype"),
    url("https://tympanus.net/Development/IconHoverEffects/fonts/codropsicons/codropsicons.woff")
      format("woff"),
    url("https://tympanus.net/Development/IconHoverEffects/fonts/codropsicons/codropsicons.ttf")
      format("truetype"),
    url("https://tympanus.net/Development/IconHoverEffects/fonts/codropsicons/codropsicons.svg#codropsicons")
      format("svg");
  font-weight: normal;
  font-style: normal;
} */

.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar-toggler {
  background-color: #e87a28;
}

.navbar-toggler:focus {
  box-shadow: none;
}

.offcanvas-header .btn-close {
  border-radius: 50px;
}

.offcanvas-header .btn-close:active {
  box-shadow: none;
  background-color: #e87a28;
}

.space-after-navbar {
  padding: 20px;
}

.auth-menu {
  padding: 10px 1px !important;
}

.navbar-dropdown-title > * {
  padding: 10px 18px !important;
}

/* .navbar-dropdown-title.active > * {
  color: green !important;
} */

/* .navbar-all-menus.active,
.auth-menu.active {
  color: green !important;
} */

.navbar-sub-menus:hover {
  background-color: #e87a28;
  color: white;
}

#user-nav-dropdown,
.welcome-text {
  padding: 7px 20px;
}

#user-nav-dropdown {
  color: black;
  border: 1px solid rgb(189, 189, 189);
}

.fa-user-circle {
  width: 28px;
  height: 40px;
  color: #e87a28;
}

.dropdown-item:hover {
  background: #e87a28;
  color: white;
}
.dropdown-menu .active:not(.form-control) {
  color: white !important;
  background-color: #e87a28 !important;
}

.dropdown-menu.show {
  inset: 8px auto auto -40px !important;
}
.welcome-text {
  color: black !important;
}

.navbar-all-menus {
  margin: 0px 1px;
  padding: 10px 18px !important;
  font-weight: bolder;
  font-size: medium;
}

.navbar-dropdown-title {
  font-weight: bolder;
}

.dropdown-toggle.nav-link:hover,
.dropdown-toggle.nav-link:focus {
  color: #e87a28 !important;
}

.navbar-all-menus.active,
.auth-menu.active,
.navbar-sub-menus.active {
  color: #e87a28 !important;
}

.login-menu,
.signup-menu {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.navbar-toggler {
  box-shadow: none;
  padding: 10px 15px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.navbar-toggler-icon {
  font-size: 16px;
}

.navbar-all-menus:hover,
#user-nav-dropdown:hover {
  color: #e87a28 !important;
  cursor: copy;
}

.navbar-light .navbar-nav .nav-link {
  color: black;
}

.navbar-white .navbar-all-menus.active {
  color: #e87a28 !important;
}

.navbar-white .navbar-all-menus.active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #fff;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.navbar-light .navbar-all-menus.active::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #e87a28;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.navbar-white .navbar-all-menus:hover {
  color: #e87a28 !important;
}

.right-dropdown .dropdown-menu {
  left: auto !important;
  right: -105px !important;
}

.login-signup-button.login-signup-button:hover {
  cursor: pointer !important;
}

.login-signup-button.login-signup-button:hover .signup-menu {
  background-color: white !important;
  color: #e87a28 !important;
  border: 1px solid #e87a28;
}

.signup-menu {
  background-color: #e87a28;
  color: white;
  border-radius: 6px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 15px;
  border: 1px solid transparent;
}

.login-signup-modal {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

div.col-lg-10.navbar-nav {
  padding: 0px 0px 0px 0px !important;
  margin: 0px !important;
}

.cancel-logout-button {
  background-color: gray !important;
  border: 1px solid gray !important;
}
.success-logout-button {
  border: 1px solid #e87a28 !important;
}

.cancel-logout-button:focus,
.success-logout-button:focus {
  background-color: transparent !important;
  color: #e87a28 !important;
  border: 1px solid #e87a28 !important;
  box-shadow: none !important;
}

.cancel-logout-button:hover,
.success-logout-button:hover {
  background-color: transparent !important;
  color: #e87a28;
  border: 1px solid #e87a28 !important;
}

.navbar-menus {
  display: float;
  float: right;
}

.toggle-main-logo {
  max-width: 60% !important;
}

.btn-close {
  padding: inherit !important;
}

nav a {
  position: relative;
}

.navbar-nav li a {
  color: black;
  text-shadow: none;
  padding: 10px 0;
}

.navbar-nav li a::before {
  color: #fff;
  text-shadow: 0 0 1px rgba(255, 255, 255, 0.3);
  content: attr(data-hover);
  position: absolute;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.navbar-nav li a::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #e87a28;
  opacity: 0;
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  transform: translateY(5px);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  pointer-events: none;
}

.navbar-nav li a:hover::before,
.navbar-nav li a:focus::before {
  opacity: 0;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  transform: translateY(-2px);
}

.navbar-nav li a:hover::after,
.navbar-nav li a:focus::after {
  opacity: 1;
  -webkit-transform: translateY(0px);
  -moz-transform: translateY(0px);
  transform: translateY(0px);
}

.user-name-icon-div {
  margin-left: 25px;
}

.fa-user-circle {
  margin: 0px 15px;
}

@media screen and (min-width: 991px) {
  .navbar-all-menus {
    padding: 10px 15px !important;
  }
}

@media (max-width: 991px) {
  .navbar-white .nav-link {
    color: black !important;
  }

  .navbar-light .navbar-all-menus.active::before,
  .navbar-nav li a::after {
    content: none;
  }

  .css-hz1bth-MuiDialog-container {
    height: 30% !important;
    position: relative;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 70%;
  }
  .user-name-icon-div {
    margin-left: 5px;
  }
  .navbar-location-submenus {
    padding-left: 20px;
  }
}

@media (max-width: 899px) {
  .css-hz1bth-MuiDialog-container {
    height: 25% !important;
    width: 70%;
  }
}

@media (max-width: 426px) {
  .css-hz1bth-MuiDialog-container {
    width: 90%;
  }
}
