.booking-charges-evaluation-step1 {
  padding: 1px;
}

.h4,
h4 {
  margin-bottom: 0px !important;
}

.location-heading {
  color: #e87a28;
  font-weight: 600;
}

.step1-car-name {
  font-weight: 900;
  text-transform: uppercase;
}

.city-name,
.step1-car-type {
  color: gray;
}
.loc-details-div, .price-break-down-container {
  background-color: #f7f7f7;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.loc-detail-icon {
  margin-left: 3px;
  margin-right: 9px;
  color: #e87a28;
}

.pickup-location-value,
.dropoff-location-value {
  font-weight: 600;
}

.step1-car-details,
.step1-location-details {
  padding: 15px;
  border-radius: 6px;
  padding: 10px;
}

.step1-car-details {
  padding: 5px;
}

.carousel .control-next.control-arrow:before {
  border-left: 8px solid black;
}

.carousel .control-prev.control-arrow:before {
  border-right: 8px solid black;
}

.booking-detail-heading {
  font-weight: 700;
  color: #e87a28;
}

.total-days-row {
  line-height: 300%;
  padding: 10px 35px 0px 30px;
}

.total-days-value {
  float: right !important;
}

.total-days-label,
.total-days-value {
  font-size: 24px;
  font-weight: 600;
}

.price-details-div {
  border-radius: 6px;
  padding: 0px 0px 5px 0px;
}

.charges-value,
.sub-total-price-value,
.discounted-value {
  font-size: 16px;
  font-weight: 700;
  color: #e87a28;
}

.sub-total-price-label {
  font-size: 14px;
}

.grand-total-price-label {
  font-weight: 700;
  font-size: 16px;
}

.grand-total-price-value,
.deleted-grand-total-price-value {
  color: #e87a28;
  font-weight: 700;
  font-size: 30px;
}

.car-features-main-container {
  border: 1px solid rgb(224, 224, 224);
  border-radius: 6px;
  padding: 8px;
}

.vertical-line-car-details-page {
  border-left: 1px solid rgb(233, 233, 233);
  height: 790px;
  position: absolute;
  left: 56%;
}

.features-icons-heading {
  color: #e87a28;
}


.features-values {
  font-size: 13px;
}

.carousel-status {
  display: none;
}

.car-features-div2-heading,
.car-description-div2-heading {
  font-weight: 400;
  color: #e87a28;
}

.car-features-text-2 {
  font-size: 14px;
}

.coupon-input-fields-div {
  display: flex;
  justify-content: flex-end;
}

.charges-section-2 {
  line-height: 80%;
}

.coupon-label-div {
  margin-top: 14px;
}

.btn.btn-success,
.btn.btn-danger {
  box-shadow: none;
}

.car-description-div {
  border: 1px solid rgb(235, 235, 235);
}

.car-description-text-2 {
  font-size: 14px;
  padding: 10px;
}

.hr-line-heading-scroll {
  margin: 5px 0px;
}

.deleted-grand-total-price-value:before {
  content: "";
  position: absolute;
  margin-top: 10px;
  width: 64px;
  border-bottom: 2px solid green;
  transform: rotate(-15deg);
  -webkit-transform: rotate(-30deg);
}

.coupon-discount-value {
  position: absolute;
  margin: 25px 5px 7px 32px;
  color: green;
}

/* 2nd step Booking & Documents  */

.heading-underline {
  border: 1px solid #e87a28;
  opacity: 0.8;
  width: 200px;
}

.heading-icon {
  padding: 6px;
  border-radius: 6px;
  width: 30px;
  height: 30px;
  background-color: #f7e2d5;
}

.add-on-icon {
  padding: 7px;
  border-radius: 6px;
  width: 40px;
  height: 50px;
  background-color: #e3e3e3;
}

.dropdown-value {
  margin-top: 10px;
}

.form-control-fname,
.form-control-lname {
  height: 45px;
  border: 1px dashed rgb(158, 158, 158);
  border-radius: 6px;
  padding: 10px 10px;
  box-shadow: none;
  font-size: 13px;
  background-color: white !important;
  cursor: pointer;
}

.input-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.input {
  min-height: 50px;
  max-width: 150px;
  padding: 0 1rem;
  color: #fff;
  font-size: 15px;
  border: 1px solid #e87a28;
  border-radius: 6px 0 0 6px;
  background-color: transparent;
}

.button--submit {
  min-height: 45px;
  padding: 0.5em 1em;
  border: none;
  box-shadow: none;
  border-radius: 0 6px 6px 0;
  background-color: #e87a28;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.button--submit:hover {
  background-color: #e87a28;
}

.remove-coupon-btn {
  background-color: #e87a28;
  box-shadow: none;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.btn.btn-secondary {
  background-color: #e87a28;
  border: none;
  box-shadow: none;
}

.btn.btn-secondary:focus {
  background-color: #d19268;
}

.car-image-1 {
  max-width: inherit;
}

@media screen and (max-width: 991px) {
  .car-prices-details-container {
    border: none;
  }
  .features-scroll-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 425px) {
  .car-features-list-main-div {
    display: block;
    width: 200%;
  }
}
