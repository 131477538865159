.auth-unselected-login-button,
.auth-unselected-signup-button {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: #f7f7f7;
  box-shadow: #efefef -3px -3px 6px 0px inset, #efefef 3px 3px 6px 1px inset;
}

.auth-unselected-login-button {
  box-shadow: #ccc -4px 0px 6px 0px inset;
}

.auth-unselected-signup-button {
  box-shadow: #ccc 4px 0px 6px 0px inset;
}

.auth-selected-button {
  padding: 10px;
  text-align: center;
  color: #e87a28;
}

.forgotpassword-form-container,
.login-form-container,
.signup-form-container {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 0px 20px;
}
.forgotPassword-button,
.resetPassword-button,
.login-button,
.signup-button,
.update-password-button {
  padding: 5px 20px;
  background-color: #e87a28;
  color: white;
  border: 1px solid #e87a28;
  border-radius: 5px;
}

.forgotPassword-button:hover,
.resetPassword-button:hover,
.login-button:hover,
.signup-button:hover,
.update-password-button:hover {
  background-color: white;
  color: #e87a28;
}

.br-for-small-screen {
  display: none;
}

.login-signup-img-container {
  position: relative;
  height: 100%;
}

.login-signup-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.update-password-text {
  color: #e87a28;
  text-align: center;
}

@media screen and (max-width: 991px) {
  .login-signup-img-container {
    display: none !important;
  }
}

@media screen and (max-width: 576px) {
  .br-for-small-screen {
    display: block;
  }

  .login-button-col {
    padding-top: 10px;
  }
}

/* Old login  */

.ftco-section {
  font-size: 16px;
  line-height: 1.8;
  font-weight: normal;
  color: gray;
  position: relative;
  z-index: 0;
  padding: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.ftco-section:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  opacity: 0.9;
  z-index: -1;
}

.bg-primary {
  background: #fbceb5 !important;
}

.heading-section {
  font-size: 28px;
  color: #fff;
}

.img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.form-group-price-min,
.form-group-price-max {
  position: relative;
  border-radius: 6px;
  margin-bottom: 1rem;
  display: grid;
}

.form-group {
  position: relative;
  border-radius: 6px;
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid rgb(184, 184, 184) !important;
  border-radius: 6px;
  background-color: white !important;
}

.field-icon {
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.login-wrap,
.signup-wrap,
.forgot-wrap {
  position: relative;
  color: black;
  font-weight: bolder;
  /* border: 1px solid rgb(193, 193, 193); */
  /* padding: 10px 0px 0px 0px; */
  /* border-radius: 20px; */
  background-color: white;
}
.login-wrap h3 {
  font-weight: 300;
  color: #fff;
}
.login-wrap .social {
  width: 100%;
}
.login-wrap .social a {
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: #000;
  background: #fff;
}
.login-wrap .social a:hover {
  background: #e87a28;
  color: #fff;
  border-color: #e87a28;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}


.forgotPassword-heading,
.resetpassword-heading,
.login-heading,
.signup-heading {
  font-size: 26px;
  color: #e87a28;
}

.forgot-row {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #80808047;
  border-radius: 10px;
  padding: 30px 5px;
  margin: 30px 15px;
}

/* .btn.btn-primary.login-button {
  width: 100%;
  height: 55px;
  border-radius: 0;
  padding: 13px 0;
  background-color: #e87a28;
  border: none;
  font-weight: 600;
  border-radius: 4px;
} */

.forgot-password-heading {
  color: rgb(255, 255, 255);
  display: inline-flex;
}

.forgot-password,
.click-here {
  color: #e87a28;
  text-decoration: underline;
}

.forgot-password:hover,
.click-here:hover {
  text-decoration: underline !important;
  color: rgb(213, 139, 0);
  cursor: pointer;
}

.field-icon {
  color: rgb(139, 139, 139);
}

.facebook-login,
.gmail-login {
  padding: 8px 20px;
  border-radius: 6px;
  font-size: small;
}

.form-group-2 {
  display: flex;
  float: right;
}

.form-group-3 {
  justify-content: space-around;
  display: flex;
}

/* .custom-button {
  display: inline-block;
  padding: 10px 40px;
  margin: 0 30px;
  border: 1px solid #e87a28;
  box-shadow: none;
  border-radius: 6px;
  background-color: #e87a28;
  color: #e87a28;
  font-size: 11px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
} */

.custom-button:hover {
  background-color: white;
  color: black !important;
}

.inputgroup,
.readOnlyInputGroup {
  margin: 1em 0 0 0;
  position: relative;
}

.inputgroup input,
.readOnlyInputGroup input {
  font-size: 70%;
  padding: 0.4em 0.4em 0.4em 1em;
  outline: none;
  border: 2px solid rgb(200, 200, 200);
  background-color: transparent;
  border-radius: 20px;
  width: 100%;
  font-weight: 500;
}

.inputgroup label,
.readOnlyInputGroup label {
  font-size: 75%;
  position: absolute;
  left: 0;
  padding-left: 1.8em;
  padding-top: 0.45em;
  margin-left: 0.5em;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  color: rgb(0, 0, 0);
  margin-top: -3.25em;
  font-weight: 200;
}

.inputgroup input:focus + label,
.readOnlyInputGroup label {
  transform: translatey(-50%) scale(0.9);
  margin: -3.3em;
  margin-left: 2.3em;
  padding: 0.1em 0.4em;
  font-weight: 700;
  background-color: white;
  font-size: 85%;
}

.inputgroup.input-filled label,
.inputgroup label {
  transform: translatey(-50%) scale(0.9);
  margin: -3.3em;
  margin-left: 2.3em;
  padding: 0.1em 0.4em;
  font-weight: 700;
  background-color: white;
  font-size: 85%;
}

.readOnlyInputGroup input {
  background-color: rgb(236, 236, 236, 0.6) !important;
}

.signup-page-labels {
  display: flex;
  align-items: center;
}

/* Sign Up  */

.map-loc-middle {
  background-color: inherit;
  border: none;
  transform: translate(0%, 0%);
}

.middle {
  border: none;
  background-color: transparent;
}

.animate-button,
.all-cars-animate-button {
  position: relative;
  display: block;
  font-size: 14px;
  border: 2px solid #e87a28;
  overflow: hidden;
  background-color: #e87a28;
  color: white;
}
.animate-button {
  padding: 10px 30px;
  border-radius: 20px;
}
.animate-button:hover,
.all-cars-animate-button:hover {
  color: #e87a28;
  background-color: white;
}

.start-booking-next-button-text {
  border-radius: 10px;

}

.all-cars-animate-button {
  padding: 10px;
  border-radius: 20px;
}

.pay-now-button {
  padding: 10px 20px;
  text-transform: capitalize;
}

@media screen and (max-width: 768px) {
  .pay-now-button {
    padding: 10px;
    font-size: 10px;
  }
}
