.crsl {
  margin: auto;
}

.vehicelDetail-main-container {
  padding-top: 16px;
}

.csontainer {
  padding: 0px !important;
}

.price-day-main-div {
  padding: 0px 10px;
}

.vehicle-images-carousel-container {
  max-width: 100%;
}

.image-gallery-slides {
  border: 1px solid #e87a28;
  padding: 1px;
}

.image-gallery-slide,
.image-gallery-image,
.image-gallery-slides {
  border-top-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.image-gallery-thumbnail-image {
  border-radius: 10px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 1px solid #e87a28 !important;
  border-radius: 10px !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 35px !important;
  width: 35px !important;
}

.image-gallery-right-nav .image-gallery-svg,
.image-gallery-left-nav .image-gallery-svg {
  border: 1px solid white !important;
  color: #e87a28 !important;
  background-color: white !important;
  border-radius: 28px !important;
}

.image-gallery-right-nav .image-gallery-svg:hover,
.image-gallery-left-nav .image-gallery-svg:hover {
  color: white !important;
  background-color: #e87a28 !important;
  border-color: #e87a28 !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: none !important;
}

.carousel .control-dots {
  display: none !important;
}

.booking-wishlist-button {
  margin-top: 20px;
  text-align: center;
}

.stm-icon-whatsapp {
  padding-right: 20px;
}
.whatsapp-container {
  border-radius: 5px;
}

.whatsapp-container:hover {
  background-color: white;
}

.car-img-modal-class .modal-overlay .modal-content img {
  width: 65%;
  top: 50%;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0%);
}

.price-row,
.total-price-row,
.coupon-main-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
}

.price-label,
.coupon-label {
  font-weight: 500;
  font-size: 14px;
}

.price-value {
  color: #e87a28;
  float: right !important;
}
.price-booking-button-div {
  margin-top: 50px;
  border: 1px solid rgb(233, 233, 233);
  border-radius: 6px;
  padding-bottom: 20px;
}

.price-range-error {
  border-color: red;
}
.price-range-error:focus {
  outline: none !important;
}
.price-range-error-message {
  font-size: small;
}

/* Pagination */

ul.pagination-container.pagination-bar {
  padding-left: 0rem;
}

.pagination-container {
  display: flex;
  list-style-type: none;
}

.pagination-item {
  padding: 13px 15px;
  height: 38px;
  text-align: center;
  margin: auto 10px;
  color: white;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.01071em;
  border-radius: 50px;
  line-height: 0.5;
  box-shadow: 1px 0px 3px #e87a28;
  background-color: #e87a28;

}
  
.pagination-item:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.pagination-item .arrow::before {
  position: relative;
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border-right: 0.15em solid white;
  border-top: 0.15em solid white;
}

.pagination-item .arrow.left {
  transform: rotate(-135deg) translate(-20%);
}

.pagination-item .arrow.right {
  transform: rotate(45deg);
}

.pagination-item.disabled {
  background-color: rgb(169, 169, 169);
}

.pagination-item.disabled:hover {
  cursor: not-allowed;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pagination-item.first {
  box-shadow: none;
}

.pagination-item.last {
  box-shadow: none;
}

.all-cars-details {
  padding: 2px;
}

.one-car-card {
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 15px 10px;
  margin: 15px 1px;
  background-color: white;
}

.rating-div,
.car-title-div {
  color: "#e87a28";
  padding: 12px 10px 4px 10px;
}

.vehicle-img-tag img {
  border-radius: 20px;
}

.vehicle-images-modal-class {
  top: 50% !important;
  left: 50%;
  transform: translate(-50%, -50%);
}

.price-day-div {
  padding: 4px 8px;
  border-radius: 10px;
}

.value-del-line {
  text-decoration-color: red;
  color: #e87a28;
}

.per-specificDay-heading {
  color: gray;
}

.AED-text {
  text-decoration-color: red;
  color: #e87a28;
}

.final-discounted-value-span {
  color: #e87a28;
}

.price-per-specificDay-container:hover {
  background: #eeeeee;
  cursor: pointer;
  transform: scale(1.03);
  transition: 0.4s ease-in-out;
}

.price-per-specificDay-container:hover
  .price-day-div
  .card-text
  .per-specificDay-heading {
  color: black;
}

.car-type-filter {
  max-height: 240px;
  overflow-y: auto;
}

div.col-lg-4.col-md-4 {
  padding: -1px 8px;
}

.all-cars-img-div {
  display: flex;
  justify-content: center;
}

img.all-cars-img {
  width: 330px;
  height: 320px;
}

.all-cars-container-div {
  padding: 6px;
}

.car-detail-price-main-div {
  padding: 50px 0px 30px 10px;
}

.booking-button {
  background-color: rgb(90, 201, 211) !important;
}

.booking-button:hover {
  background-color: white !important;
  color: black !important;
}

.features-icon-detailPage {
  max-width: 50%;
}

.features-icon-name {
  color: gray;
}

.rental-addons-main-heading {
  padding: 10px 20px;
  background-color: #e87a28;
  color: white;
  border-radius: 10px;
}

span.select2-selection_arrow b {
  display: contents !important;
}

.select2-container .select2-selection--single {
  height: 45px !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 43px !important;
  padding: 0px 19px !important;
  font-size: 13px;
  color: #1c1c1c !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  height: 47px !important;
}

.select2-container .select2-selection--multiple {
  min-height: 40px !important;
}
.select2-selection--multiple {
  padding: 8px;
}

.select2-container .select2-search--inline .select2-search__field {
  height: 20px !important;
  margin-top: 1px !important;
}

.min-max-price-hypen {
  display: flex;
  align-items: center;
  justify-content: center;
}

#formMinMaxPrice {
  width: 100% !important;
}

.all-cars-hr-tag {
  opacity: 0.9;
}

.sort-by-div {
  border-radius: 6px;
  border: 1px solid #cccccc;
}

.price-filter-heading,
.car-type-filter-heading {
  border-radius: 0px !important;
  border-top: 1px solid rgba(0, 0, 0, 0.175);
}

.location-data-heading {
  border: 1px solid #6e6e6e13;
}

.single-car-container-div {
  border: 1px solid rgb(217, 217, 217);
  border-radius: 15px;
  padding: 10px 20px;
  background: url("../../images//vehicleBg.png");
  background-size: contain;
}

.single-car-container-div:hover {
  box-shadow: 4px 3px 12px 5px #b4b3b3;
  transition: opacity 0.6s ease;
}

.single-car-container-div:hover .car-image {
  transform: scale(1.03);
  transition: 0.3s ease-in-out;
}

#hr-value-allcars-1 {
  transform: rotate(135deg);
  width: 45px;
  position: absolute;
  border-bottom: 2px solid #e87a28;
  height: 64px;
  top: 6px;
  left: 27px;
}

#hr-value-allcars-2 {
  transform: rotate(135deg);
  width: 101px;
  position: absolute;
  border-bottom: 2px solid #e87a28;
  height: 59px;
  top: 27px;
  left: 18px;
}

.offer-car-price {
  position: absolute;
  transform: rotate(-45deg);
  top: 16px;
  left: 7px;
}

.car-name {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.car-category {
  color: rgba(255, 255, 255, 0.72);
}

.car-price-name-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
}

.car-details-div {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.product-panel__price span {
  display: block;
  color: #838383;
  font-size: 17px;
  padding-bottom: 8px;
  font-weight: 400;
}

.product-panel__price {
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  color: #e87a28;
}

@media (min-width: 1024px) {
  .product-panel__price {
    font-size: 23px;
  }
}

@media (max-width: 1024px) {
  .form-check-label,
  .car-name {
    font-size: smaller;
  }
  .vehicle-images-modal-class {
    top: 70% !important;
  }
}

.per-day-vehicle-price {
  color: #ffffffc7;
}

.all-car-icons,
.fleet-icons-name,
.vehicle-per-day-price-value {
  color: white;
}

.vehicle-total-price {
  color: #e87a28;
}

.filters-main-container {
  padding: 5px 5px 20px 5px;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  background-color: #eeeeee;
}

/* .filters-section {
  padding: 5px 5px 20px 5px;
  border-radius: 6px;
  height: fit-content;
} */

.filters-text {
  color: white;
}

.filters-heading {
  font-weight: 500;
  border: 1px solid #cccccc;
  background-color: #e87a28;
  padding: 15px 10px;
  border-radius: 6px;
}

.clear-filters {
  color: white;
  cursor: pointer;
}

.clear-filters:hover {
  text-decoration: underline;
}

.sort-by-select-tag:focus {
  box-shadow: none !important;
  border-color: #ccc;
}

.categories-open-icon,
.brand-open-icon,
.price-range-open-icon,
.type-open-icon,
.location-data-open-icon {
  font-size: larger;
  font-weight: bold;
}

.categories-open-icon:hover,
.brand-open-icon:hover,
.price-range-open-icon:hover,
.type-open-icon:hover,
.location-data-open-icon:hover {
  cursor: pointer;
}

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;700&display=swap");

.car-type-filter * :not(:empty) {
  transform-style: preserve-3d;
}

.checkboxes {
  width: 100%;
  max-width: 180px;
}

.flipBox {
  position: relative;
  display: block;
  text-align: left;
  margin: 1em auto;
  cursor: pointer;
}

.flipBox:active > .flipBox_boxOuter {
  height: 25%;
}

.flipBox span {
  padding-left: 0.25em;
}

.flipBox > input {
  appearance: none;
  position: fixed;
  left: -100vmax;
  top: -100vmax;
}

.flipBox > input:checked ~ .flipBox_boxOuter {
  animation: flipBox1 0.5s forwards linear;
}

.flipBox > input:checked ~ .flipBox_boxOuter .flipBox_box {
  animation: flipBox2 0.5s forwards ease-in-out;
}

.flipBox > input:checked ~ .flipBox_shadow {
  animation: flipBox3 0.5s forwards ease-in-out;
}

.flipBox > input:not(:checked) ~ .flipBox_boxOuter {
  animation: flipBox4 0.5s forwards linear;
}

.flipBox > input:not(:checked) ~ .flipBox_boxOuter .flipBox_box {
  animation: flipBox5 0.5s forwards ease-in-out;
}

.flipBox > input:not(:checked) ~ .flipBox_shadow {
  animation: flipBox6 0.5s forwards ease-in-out;
}

.flipBox > input:disabled ~ * {
  cursor: not-allowed;
}

.flipBox > input:disabled ~ .flipBox_boxOuter {
  height: 0%;
}

.flipBox > input:disabled ~ .flipBox_boxOuter .flipBox_box > div:nth-child(6) {
  background-color: #ccc;
}

.flipBox
  > input:disabled
  ~ .flipBox_boxOuter
  .flipBox_box
  > div:nth-child(-n + 5),
.flipBox > input:disabled ~ .flipBox_shadow {
  display: none;
}

.flipBox > input:focus-visible ~ span {
  outline: 1px #000 solid;
  outline-offset: 0.25em;
}

.flipBox_boxOuter {
  position: absolute;
  left: 0;
  top: 50%;
  width: 1em;
  height: 1em;
  transform-origin: top;
  transition: height 0.25s;
}

.flipBox_box {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1em;
  height: 100%;
  transform: translate(-50%, -50%);
}

.flipBox_box > div {
  position: absolute;
  width: 1em;
  border: 1px solid rgba(180, 180, 180, 0.673);
}

.flipBox_box > div:nth-child(1) {
  background-image: linear-gradient(#e87a28, white);
  transform: rotateY(0deg) translateX(50%) rotateY(90deg);
  height: 100%;
}

.flipBox_box > div:nth-child(2) {
  background-image: linear-gradient(#e87a28, white);
  transform: rotateY(90deg) translateX(50%) rotateY(90deg);
  height: 100%;
}

.flipBox_box > div:nth-child(3) {
  background-image: linear-gradient(#e87a28, white);
  transform: rotateY(180deg) translateX(50%) rotateY(90deg);
  height: 100%;
}

.flipBox_box > div:nth-child(4) {
  background-image: linear-gradient(#e87a28, white);
  transform: rotateY(270deg) translateX(50%) rotateY(90deg);
  height: 100%;
}

.flipBox_box > div:nth-child(5) {
  background-color: #e87a28;
  transform: rotateX(90deg) translateY(-0.5em);
  transform-origin: top;
  height: 1em;
}

.flipBox_box > div:nth-child(5)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  clip-path: polygon(
    12.5% 56.25%,
    25% 43.75%,
    37.5% 56.25%,
    75% 18.75%,
    87.5% 31.25%,
    37.5% 81.25%,
    12.5% 56.25%
  );
}

.flipBox_box > div:nth-child(6) {
  bottom: 0;
  background-color: white;
  transform: rotateX(90deg) translateY(0.5em);
  transform-origin: bottom;
  height: 1em;
}

@keyframes flipBox1 {
  0%,
  100% {
    transform: rotateX(90deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    animation-timing-function: ease-in;
  }
}

@keyframes flipBox2 {
  from {
    transform: translate(-50%, -50%) rotateX(0deg) rotateY(180deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
}

@keyframes flipBox3 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    animation-timing-function: ease-in;
  }
}

@keyframes flipBox4 {
  0%,
  100% {
    transform: rotateX(90deg);
    animation-timing-function: ease-out;
  }
  50% {
    transform: rotateX(90deg) translateY(2em);
    animation-timing-function: ease-in;
  }
}

@keyframes flipBox5 {
  from {
    transform: translate(-50%, -50%) rotateX(-180deg) rotateY(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateX(-360deg) rotateY(180deg);
  }
}

@keyframes flipBox6 {
  0%,
  100% {
    transform: translateY(-50%) translateZ(-1px) scale(1);
    animation-timing-function: ease-out;
  }
  50% {
    transform: translateY(-50%) translateZ(-1px) scale(1.5);
    animation-timing-function: ease-in;
  }
}

@media (prefers-reduced-motion) {
  .flipBox_boxOuter,
  .flipBox_box,
  .flipBox_shadow {
    animation-play-state: paused !important;
  }

  .flipBox_boxOuter {
    transition: none;
  }
}

.rdrCalendarWrapper {
  border: 1px solid #e3e3e3;
  border-radius: 6px;
  position: absolute;
  z-index: 999;
}

.rdrDayDisabled {
  background-color: white !important;
}

.rdrMonthAndYearPickers select option {
  background-color: white;
  color: #000;
}

.rdrMonthAndYearPickers select {
  color: white !important;
  padding: 10px 27px !important;
  background-image: url("data:image/svg+xml;utf8,<svg width='9px' height='6px' viewBox='0 0 9 6' version='1.1' xmlns='https://www.w3.org/2000/svg' xmlns:xlink='https://www.w3.org/1999/xlink'><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' transform='translate(-636.000000, -171.000000)' fill-opacity='1'><g id='input' transform='translate(172.000000, 37.000000)' fill='%230E242F' fill-rule='nonzero'><g id='Group-9' transform='translate(323.000000, 127.000000)'><path d='M142.280245,7.23952813 C141.987305,6.92353472 141.512432,6.92361662 141.219585,7.23971106 C140.926739,7.5558055 140.926815,8.06821394 141.219755,8.38420735 L145.498801,13 L149.780245,8.38162071 C150.073185,8.0656273 150.073261,7.55321886 149.780415,7.23712442 C149.487568,6.92102998 149.012695,6.92094808 148.719755,7.23694149 L145.498801,10.7113732 L142.280245,7.23952813 Z' id='arrow' fill='white'></path></g></g></g></svg>") !important;
  background-color: #e87a28 !important;
}

.rdrDayNumber span {
  font-weight: 600;
  font-size: larger;
}

.rdrDayDisabled .rdrDayNumber span {
  color: #a2abb0 !important;
}

.rdrDayDisabled .rdrDayNumber {
  background-color: white !important;
  cursor: not-allowed;
}

.rdrDayPassive .rdrDayNumber span {
  color: #d0d2d2 !important;
}

.rdrDayPassive .rdrDayNumber {
  background-color: white !important;
  cursor: not-allowed;
}

.rdrInRange {
  background: #e87a28 !important;
  border-left-color: white;
  border-right-color: white;
}
.rdrStartEdge {
  background-color: #e87a28 !important;
}
.rdrEndEdge {
  background-color: #e87a28 !important;
}

.rdrStartEdge:hover {
  border-color: red !important;
}
.all-cars-heading {
  color: #e87a28;
  font-weight: 600;
  text-align: center;
}

.search-filters-card,
.location-data-card {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 6px;
}

.car-features-vertical-line {
  color: #d0d0d0;
}

h1.vehicle-details-heading {
  font-size: 24px;
  font-weight: 600;
}

@media screen and (max-width: 575px) {
  .pay-now-value {
    font-size: 14px;
  }
  .rdrMonthAndYearPickers select {
    padding: 7px 20px !important;
  }
  .rdrCalendarWrapper {
    font-size: 10px !important;
  }
  .single-car-container-div {
    padding: 10px 15px;
  }
}
.pay-now-price-xs {
  display: none;
}

@media screen and (max-width: 375px) {
  .pay-now-price-xs {
    display: block;
  }

  .pay-now-price-md-lg {
    display: none;
  }
}

.filters-section {
  position: relative;
}

.filters-section .filters-button,
.apply-filters-button,
.cancel-filters-button {
  display: none;
}

.filters-section .filters-content {
  display: block;
}

.filters-cross-button-row {
  display: none;
}

/* For smaller screens */
@media (max-width: 767px) {
  .filters-section .filters-button {
    display: block;
    /* position: fixed; */
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    background-color: #e87a28;
    color: white;
    border: none;
    border-radius: 10%;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters-section .filters-content {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    z-index: 1000;
    transition: transform 0.3s ease-in-out;
    transform: translateY(100%);
    max-height: 90%;
    overflow-y: auto;
    padding: 20px;
    box-shadow: 0 -2px 10px rgba(138, 114, 114, 0.1);
  }

  .filters-section .filters-content.open {
    margin: 8px;
    transform: translateY(0);
    border: 1px solid #cdcdcd;
  }

  .filters-section .filters-content .close-button {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }

  .filters-cross-button-row {
    display: block;
    display: flex;
  }

  .filters-content {
    box-shadow: 2px 2px 20px 2px #e87a28 !important;
    border: 1px solid gray !important;
  }

  .no-scroll {
    overflow: hidden;
    height: 100%;
  }

  .filters-content.open {
    overflow-y: auto;
    max-height: 90vh;
  }

  .apply-filters-button,
  .cancel-filters-button {
    display: block;
  }

  .apply-filters-button,
  .cancel-filters-button {
    padding: 5px 20px;
    background-color: #e87a28;
    color: white;
    border: 1px solid #e87a28;
    border-radius: 5px;
  }

  .cancel-filters-button {
    padding: 5px 20px;
    background-color: white;
    color: #e87a28;
    border: 1px solid #e87a28;
    border-radius: 5px;
  }

  .cross-filters-button {
    /* width: 35px; */
    /* height: 35px; */
    padding: revert;
    background-color: white;
    color: #e87a28;
    border: 1px solid #e87a28;
    border-radius: 50px;
  }

  .apply-filters-button:hover,
  .cancel-filters-button:hover {
    background-color: white;
    color: #e87a28;
  }
}
