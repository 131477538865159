.lds-dual-ring,
.lds-dual-ring:after {
  box-sizing: border-box;
}
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  color: #e87a28;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6.4px solid currentColor;
  border-color: currentColor transparent currentColor transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.reloading-icon-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  visibility: visible;
  background-color: white;
}

.reloading-icon-of-form-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  visibility: visible;
  background-color: white;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.reloading-icon-booking-page-container {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  visibility: visible;
  background-color: transparent;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
}

.loader-text {
  color: #e87a28;
  font-weight: 600;
  padding: 10px 20px;
  border-radius: 10px;
  /* border: 1px solid #e87a28; */
  background: white;
}
.loadings {
  visibility: hidden;
}

.loadings .reloading-icon-container {
  visibility: visible;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
