.error-404-page {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.error-404-div-container {
  text-align: center;
  margin-top: 3rem;
  border: 1px solid white;
  border-radius: 10px;
  background-color: rgba(227, 227, 227, 0.575);
  max-width: fit-content;
  padding: 20px;
}

.error-404-icon {
  width: 15%;
  padding: 0px 0px 20px 0px;
}

.error-404-page-small-line {
  width: 15%;
  border: 1px solid #e87a28;
  margin: 1rem auto !important;
  opacity: 1;
}
