.booking-details-button {
  background-color: #e87a28;
  color: white;
  padding: 8px;
  box-shadow: none;
  border-radius: 6px;
  border: 1px solid #e87a28;
}

.booking-details-button:hover {
  background-color: white;
  color: #e87a28;
}

.customer-bookings-status-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table th {
  font-size: large;
  font-weight: 200 !important;
  text-transform: capitalize;
  background-color: #e87a28;
  color: white;
  padding: 15px 2px;
}

.table td,
.table th {
  text-align: center;
  font-size: 14px;
}

.card-section {
  text-align: left;
}

.customer-booking-status,
.customer-payment-status {
  border: 1px;
  padding: 7px 10px;
  border-radius: 6px;
  color: white;
  opacity: 0.9;
  display: inline-block;
}

.customer-payment-status.bg-green,
.customer-booking-status.bg-green {
  background-color: green;
}

.customer-payment-status.bg-orange,
.customer-booking-status.bg-orange {
  background-color: #e87a28;
}

.customer-booking-status.bg-blue {
  background-color: rgb(67, 67, 222)
}

.customer-booking-status.bg-red {
  background-color: red;
}

.booking-details-label {
  color: rgba(128, 128, 128, 0.845);
}
