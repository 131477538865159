.accordion-button,
.accordion-button:focus {
  box-shadow: 4px 2px 12px 1px #e3e3e3;
}

.accordion-button:hover {
  background-color: #e87a28;
  color: white;
}

.accordion-button:not(.collapsed) {
  background-color: #e87a28;
  color: aliceblue;
  box-shadow: none;
}

.accordion-button::after {
  background-size: 1rem;
}

.accordion-button:hover::after,
.accordion-button:focus::before,
.accordion-button:not(.collapsed)::after {
  filter: brightness(0) invert(1);
}

.about-us-main-section-1,
.about-us-main-section-2 {
  background-color: white;
}

.about-us-section-1-container {
  padding: 15px;
}

.why-us-icon-div {
  background-color: #e87a28;
  width: fit-content;
  padding: 10px;
  border-radius: 50px;
}

.why-us-row-main-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.why-us-text-col:hover {
  transform: scale(0.95);
  transition: 0.4s ease-in-out;
}

.legacy-icon {
  font-size: 26px;
  color: white;
}

.why-us-single-container {
  padding: 30px;
  background-color: #f3f3f3;
  border-radius: 10px;
}

.about-us-main-heading-1,
.about-us-mapped-data-container {
  padding: 5px 20px 0px 20px;
  text-align: left;
}

.about-us-main-heading-span {
  color: #e87a28;
}

.about-us-all-heading h4 {
  font-weight: 400;
}

.about-us-text-col,
.why-us-text-col {
  padding: 4px 24px 24px 24px;
}

.about-us-description {
  line-height: 130%;
}

.legacy-description {
  margin-top: 16px;
  text-align: left;
  color: black;
  text-decoration: none !important;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.accordion-button.collapsed {
  font-size: 15px;
}

.accordion-button {
  font-size: 15px;
  font-weight: 600;
}

.center-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.border-div-img {
  position: relative;
}

.border-div-img {
  position: relative;
}

.border-div-img::after {
  content: "";
  position: absolute;
  width: 1px;
  background-color: gray !important;
  border: 150px solid #d5d5d5;
  border-radius: 10px;
}

.border-div-img::after {
  bottom: -30px;
  left: 45%;
  height: 100%;
}

.border-div-img img {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  z-index: 1;
}

.aboutUs-heading-underline {
  border: 1px solid #e87a28;
  opacity: 0.8;
}

.contact-us-map-div {
  border: 2px solid #e87a28;
  margin: 5px;
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.map-iframe-class {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 991px) {
  .border-div-img::after {
    border: 120px solid #d5d5d5;
  }
  .border-div-img::after {
    bottom: -50px;
    left: 45%;
    height: 100%;
  }
}

@media screen and (max-width: 767px) {
  .contact-us-map-container,
  .border-div-img::after {
    display: none;
  }
}

.contact-us-address-text {
  font-size: 15px;
}

.contact-us-map {
  background-color: white;
}

.renting-text {
  background-color: white;
  padding: 2px 15px;
}

.contact-us-form-container {
  background-color: white;
}

.contact-us-page-icons {
  font-size: x-large;
  color: #e87a28;
}

.second-row-contact-us-form,
.second-row-lto-userDetails-form {
  padding-top: 1.5rem;
}

/* Terms & Conditions */

.all-terms-conditions-main-container {
  text-align: left;
}

.terms-conditions-headings,
.order-list-termination-heading li::marker,
.list-style-capital-alpha li::marker {
  color: #e87a28;
}
.terms-conditions-sub-heading-1 {
  color: #e87a28;
  font-weight: 600;
}

/* Style (a)  */
.custom-list-double-brackets-a {
  list-style: none;
  counter-reset: list-counter;
}

.custom-list-double-brackets-a li {
  position: relative;
}

.custom-list-double-brackets-a li:before {
  content: "(" counter(list-counter, lower-alpha) ")";
  counter-increment: list-counter;
  position: absolute;
  left: -2em;
  color: rgb(238, 147, 0);
}

/* Style a)  */

.custom-list-right-brackets-a {
  list-style: none;
  counter-reset: list-counter;
}

.custom-list-right-brackets-a li {
  position: relative;
}

.custom-list-right-brackets-a li:before {
  content: counter(list-counter, lower-alpha) ")";
  counter-increment: list-counter;
  position: absolute;
  left: -2em;
  color: rgb(238, 147, 0);
}

/* Style (i) */
.list-style-capital-alpha .custom-list-double-brackets-roman {
  list-style: none;
  counter-reset: list-counter;
}

.list-style-capital-alpha .custom-list-double-brackets-roman li {
  position: relative;
}

.list-style-capital-alpha .custom-list-double-brackets-roman li:before {
  content: "(" counter(list-counter, lower-roman) ")";
  counter-increment: list-counter;
  position: absolute;
  left: -2.5em;
  color: rgb(238, 147, 0);
}

.custom-list-double-brackets-roman-alpha-dot {
  list-style: lower-alpha;
  font-weight: 400;
}
.custom-list-double-brackets-roman-alpha-dot li::marker {
  color: gray;
}

.list-style-capital-alpha {
  list-style-type: upper-alpha;
}

.list-style-lower-roman {
  list-style-type: lower-roman;
}

.list-style-numbers {
  list-style-type: decimal;
}

.colored-bold-line {
  text-transform: uppercase;
  color: #e87a28;
  font-weight: 400;
}

@media screen and (max-width: 576px) {
  .second-row-contact-us-form,
  .second-row-lto-userDetails-form {
    padding-top: 0.1rem;
  }
}
