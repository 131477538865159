@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

* {
  font-family: "Poppins";
  font-optical-sizing: auto;
}

body {
  margin: 0;
  font-family: "Montserrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* .lease-car-phone-num-div,
.lease-car-whatsapp-div {
  margin: 13px 5px 0px 13px;
} */

.contact-buttons-container
/* , .lease-car-buttons-container */ {
  position: fixed;
  bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  right: 20px;
  z-index: 999;
}

.contact-button
/* , .lease-car-button  */ {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
  padding: 14px;
  border-radius: 50px;
  text-decoration: none;
  text-align: center;
  color: white !important;
  position: relative;
}

/* .whatsapp-link-lease-section {
  padding: 12px;
}

.lease-car-button {
  padding: 10px;
}

.lease-car-button,
.whatsapp-link-lease-section {
  border: 1px solid rgb(214, 214, 214);
  border-color: transparent;
}

.whatsapp-link-lease-section:hover {
  background-color: white;
  box-shadow: 1px 1px 12px 1px green;
  border: 1px solid rgb(214, 214, 214);
}

.phone-call-link-lease-section:hover {
  background-color: white;
  box-shadow: 1px 1px 12px 1px #e87a28;
  border: 1px solid rgb(214, 214, 214);
}

.phone-call-link-lease-section:hover svg {
  background-color: white;
  color: #e87a28;
}

.whatsapp-link-lease-section:hover svg {
  background-color: white;
  color: green;
}

.whatsapp-link-lease-section svg,
.phone-call-link-lease-section svg {
  font-size: 18px;
} */

.contact-button::before
/* , .lease-car-button::before */ {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.contact-button:hover::before
/* , .lease-car-button:hover::before  */ {
  left: 0;
  animation: identifier 1s ease-out infinite;
  border-radius: 50px;
  background: inherit;
}

/* .lease-car-whatsapp-div:hover 
.whatsapp-text-lto {
  color: green;
} */

.location-link {
  box-shadow: none;
}

@keyframes identifier {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

/* @keyframes wave-effect {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}

.whatsapp-link-lease-section::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}

.whatsapp-link-lease-section:hover::before {
  left: 0;
  animation: wave-effect 1s ease-out infinite;
  border-radius: 50px;
  background: inherit;
} */

.phone-call-link,
.map-link,
.phone-call-link-lease-section {
  background-color: #e87a28;
}

.gm-style div {
  cursor: pointer !important;
}

.spin-the-wheel-link {
  background-color: #e87a28;
}

.whatsapp-link,
.whatsapp-link-lease-section {
  background-color: rgba(0, 128, 0, 0.883);
}

.contact-button svg {
  margin-right: 0px;
  font-size: 26px;
}

/* ul.elementor-contact-tabs-list {
  background-color: #e87a28 !important;
  list-style: none;
} */

.single-feature-container
/* , .lease-page-single-feature-container  */ {
  box-shadow: 0.5px 0.5px 2px 0.5px #e87a28;
  border-radius: 5px;
  margin: 0px 7px;
  display: ruby;
  padding: 6px 10px 7px 9px;
  border: 1px solid #e87b2840;
}
.row {
  margin: 0px 0px 0px -10px;
}

/* .lease-page-single-feature-container {
  padding: 3px 10px 7px 9px;
} */

/* .footer_widgets_wrapper .widget_socials li a:hover {
  background-color: #e87a28 !important;
} */
/* 
.phone-number a {
  color: white;
} */

/* .header-main-socs .clearfix {
  list-style: none;
  display: flex;
  flex-direction: row;
}

.header-main-socs .clearfix li a {
  color: white;
  border: 1px solid rgb(33, 33, 33);
  padding: 9px 12px;
  margin: 7px;
  border-radius: 100px;
  background-color: rgb(59, 59, 59);
}

.header-main-socs .clearfix li a:hover {
  background-color: #e87a28;
}

.top-info-wrap {
  padding: 30px 20px 0px 0px;
}

.send-enquiry-button button:hover {
  color: white !important;
}

.header-menu.clearfix > li {
  padding: 12px 15px;
  margin: 0px 1px;
}

.header-menu.clearfix > li:hover {
  background-color: #e87a28;
}

.header-menu.clearfix > li:hover a {
  color: white;
}

.menu-item-type-custom > a {
  padding: 12px 15px;
}

.navbar-container,
.row1,
#header-nav-holder {
  background-color: rgb(187, 187, 187) !important;
}

ul.header-menu {
  margin-bottom: 0px;
}



.header-menu {
  list-style: none;
  display: flex;
  float: left;
  flex-direction: row;
  padding: 0px 14px 0px 5px;
}

.leasing-title.heading-font,
.colored-separator {
  padding: 0px 14px 5px 14px;
  color: white;
}

.heading-font {
  color: #e87a28;
  padding-bottom: 5px;
}

.swiper-container.items-per-view-3 {
  padding: 0px 30px;
}

.homepage-leasing-container {
  padding: 0px !important;
}

.menu-item-user-dropdown {
  list-style: none;
  position: relative;
}

.menu-item-user-dropdown > a {
  cursor: pointer;
}

.menu-item-user-dropdown .sub-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.menu-item-user-dropdown:hover .sub-menu {
  display: block;
  list-style: none;
}
*/

@media screen and (max-width: 991px) {
  .contact-button svg {
    font-size: 22px;
  }
  /* .lease-car-button,
  .whatsapp-link-lease-section {
    padding: 10px;
  }
  .whatsapp-link-lease-section svg,
  .phone-call-link-lease-section svg {
    font-size: 18px;
  } */
}

/* @media screen and (max-width: 767px) {
  .lease-car-button,
  .whatsapp-link-lease-section {
    padding: 15px;
  }
} */

/* @media screen and (max-width: 576px) {
  .lease-car-button,
  .whatsapp-link-lease-section {
    padding: 17px;
  }
}

@media screen and (max-width: 425px) {
  .lease-car-button,
  .whatsapp-link-lease-section {
    padding: 10px;
  }
}  */
