.customer-profile-icon svg {
  font-size: 65px;
  margin: 20px 20px 20px 0px;
  border: 1px solid #e87a28;
  border-radius: 50px;
  padding: 12px;
  background-color: #e87b2848;
}

.personal-detail-heading {
  color: #e87a28;
  text-align: center;
}

.driving-license-img {
  /* max-width: -webkit-fill-available; */
  max-width: 20%;
}
