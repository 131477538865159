@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Work+Sans:wght@800&display=swap");

.App {
  background-color: white;
}

.modal-heading,
.price-range-label,
.fleet-car-main-section {
  color: #e87a28;
}

.css-1dimb5e-singleValue {
  font-weight: 100 !important;
}

.required-field-star {
  color: #e87a28;
  font-weight: 200;
}

.navbar-div-container {
  position: relative;
  z-index: 999;
}

.terms-conditions-main-heading,
.offer-heading-icon-container-div,
.insta-testimonial-heading-icon-container-div,
.booking-doc-headings,
.about-us-headings,
.contactUs-form-heading,
.faqs-main-heading {
  padding-bottom: 5px;
  border-bottom: 2px solid;
  display: inline-flex;
  align-items: center;
}

h2.booking-page-h1 {
  font-size: 20px;
}
.all-cars-heading {
  padding-bottom: 5px;
  border-bottom: 2px solid;
}

h2.terms-conditions-headings-main {
  font-weight: 600;
  font-size: 22px;
}

.middle-hr-tag {
  margin-bottom: 1rem !important;
  opacity: 0.9;
  border: 1px solid #e87a28;
  margin-top: 1rem !important;
  margin: 1rem 36% !important;
}

@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 10%);
    --fg: hsl(var(--hue), 90%, 90%);
  }
}

.top-bar-without-container,
.bottom-bar-without-container {
  position: relative;
  background-color: #e87a28;
  z-index: 0;
}

.top-page-horizontal-line {
  justify-content: center;
  background: #e87a28;
  padding: 10px 20px;
}

.bottom-home-page-horizontal-line {
  justify-content: center;
  background: #e87a28;
  padding: 20px 0px;
}

.bottom-bar-without-container span {
  white-space: nowrap;
  overflow-wrap: break-word;
  word-break: break-word;
}

.bottom-bar-copyright-text {
  font-size: large;
}
.content-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  max-width: 1200px;
}

.left-content {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.right-content {
  display: block;
  flex-wrap: wrap;
}

.top-contact-button {
  display: flex;
  align-items: center;
  margin-right: 20px;
  text-decoration: none;
  color: white;
}

.top-contact-button span {
  margin-left: 10px;
}

.right-content a {
  margin-left: 20px;
  color: white;
  text-decoration: none;
}

.top-contact-button:hover {
  text-decoration: none;
  color: black;
}
.social-media-icons-top-bar {
  padding: 2px;
  color: white;
  font-size: 20px;
}

.social-media-icons-top-bar:hover {
  border-radius: 30px;
  background-color: white;
  color: #e87a28;
}

.bottom-home-page-horizontal-line {
  background-color: #e87a28;
}

.tag-line-heading-home-page {
  background-color: #e87a28;
  text-align: center;
}
.tag-line-heading-text {
  color: white;
  font-size: 60px;
  font-weight: 900;
  padding: 20px 0px;
}

.booking-details-container {
  background-color: #dedede;
}

.booking-price-evaluation {
  margin: auto;
  padding: 15px;
  background-color: white;
  border-radius: 6px;
}

.booking-price-evaluation-main-div {
  margin: 10px 0px 5px 0px;
  padding: 15px 15px 15px 5px;
}

.form-control {
  height: 45px;
  border: none;
  border-radius: 0;
  font-weight: 100;
  padding: 0 0 5px 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #ccc;
  margin: 0;
  font-size: 14px;
}

.form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
}

.form-control-date {
  height: 45px;
  border: 1px solid rgb(184, 184, 184) !important;
  border-radius: 6px;
  padding: 10px 10px;
  box-shadow: none;
  font-size: 14px;
  background-color: white !important;
}

.search-box-date-picker-modal .modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.search-box-date-picker-modal .modal-content {
  width: 100%;
  max-width: 290px;
  border-radius: 10px;
}

.search-box-date-picker-modal .modal-body {
  padding: 20px;
}

.border-red {
  border: 1px solid red !important;
  border-radius: 6px;
}
/* .select-error .css-1jqq78o-placeholder, */
.select-error-label {
  color: red !important;
}

.form-control-customer-number.select-error.border-red {
  border-color: red !important;
}

.additional-comments {
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
}

.form-control2 {
  font-size: 14px;
  height: 20px;
  width: 55px;
  border: none;
  border-radius: 0;
  font-weight: 800;
  padding: 0 0 5px 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #ccc;
  margin: 0;
}

.form-control2:focus {
  box-shadow: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
}

.form-control3 {
  font-size: 14px;
  height: 20px;
  width: 30px;
  border: none;
  border-radius: 0;
  font-weight: 800;
  padding: 0 0 5px 0;
  background-color: transparent;
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #ccc;
  margin: 0;
}

.form-control3:focus {
  box-shadow: none;
  border-bottom: 2px solid #ccc;
  background-color: transparent;
}

.form-control-payment {
  width: 100% !important;
}

.col-12.p-5 img {
  width: 80%;
  height: 100%;
  object-fit: fill;
}

.text-muted {
  font-size: 12px;
  font-weight: bold;
}

.textmuted {
  color: #6c757d;
  font-size: 13px;
}

.fs-14 {
  font-size: 14px;
}

.btn.btn-primary {
  width: 100%;
  padding: 14px 0;
  font-weight: 400;
  box-shadow: none;
  border-radius: 25px;
  transition: all 1s;
  overflow: hidden;
  border: none;
  font-weight: 600;
  background-color: #e87a28;
  transition: 1s ease;
}

.btn.btn-primary:active {
  background-color: white !important;
  color: white !important;
  border-color: #e87a28 !important;
  box-shadow: 2px 6px 14px #e87a28 !important;
}

.btn.btn-primary:hover {
  color: black !important;
  background-color: white;
  border: 1px solid rgb(220, 220, 220);
}

.btn.btn-primary:active {
  background-color: #e87a28 !important;
}

.btn.btn-primary::before {
  top: -1em;
  left: -1em;
}

.btn.btn-primary::after {
  left: calc(100% + 1em);
  top: calc(100% + 1em);
}

.btn.btn-primary:hover::before,
.btn.btn-primary:hover::after {
  height: 410px;
  width: 410px;
}

.btn.btn-primary:hover {
  color: white;
}

.btn.btn-primary:active {
  filter: brightness(0.9);
}

.fw-900 {
  font-weight: 900;
}

::placeholder {
  font-size: 12px;
}

.ps-30 {
  padding-left: 30px;
}

.payment-text {
  margin: 15px 0px;
}

@media (max-width: 991px) {
  .main-logo {
    max-width: 100%;
  }

  .vertical-line {
    display: none !important;
  }

  .horizontal-line {
    border-top: 1px solid #ccc;
    margin: 5px 0;
    width: 100%;
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .left-content {
    display: contents;
  }
  .right-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 767px) {
  .bottom-bar-copyright-text {
    font-size: medium;
  }
  .top-bar-without-container {
    display: none;
  }
}

@media screen and (max-width: 467px) {
  .top-bar-row-div {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .top-horizontal-line-text {
    font-size: 16px;
  }
  .bottom-bar-copyright-text {
    font-size: small;
  }
  .bottom-bar-without-container span {
    white-space: normal;
  }
}
