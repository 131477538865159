.css-8t49rw-MuiStepConnector-line {
  min-height: 50px !important;
  margin-bottom: 10px;
}

.loc-name-text-car-details {
  font-weight: 600 !important;
  font-size: 14px !important;
  overflow-wrap: anywhere !important;
}

.loc-name-car-details-page {
  /* color: #e87a28; */
  padding-left: 40px;
}

.pick-drop-data {
  padding: 10px 20px;
}

.customer-icon-stepper-container,
.car-prices-details-container {
  border-radius: 10px;
  background: rgb(255, 255, 255);
}

.pickup-dropoff-heading,
.price-breakdown-heading {
  color: white;
  font-weight: 800;
  background-color: #e87a28;
  padding-top: 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.pick-drop-data {
  overflow: auto;
}


.react-datetime-picker__inputGroup__input:invalid {
  background-color: transparent !important;
}

.react-datetime-picker__wrapper {
  border: none !important;
}