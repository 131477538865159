#image-section {
  width: 100%;
  height: auto;
}

.imgBox {
  min-width: 200px;
}

.lto-main-heading {
  color: #e87a28;
  font-weight: 600;
}

.lto-headings {
  color: #e87a28;
  text-align: center;
  font-weight: 600;
}

.heading-5-lto,
.dealing-brands-lto-text {
  margin-bottom: 0;
}

.heading-4-lto {
  margin-bottom: 15px !important;
}

.main-car-img-lto {
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
.home-page-lto-brand-icons {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
}
.lto-brand-icons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.lto-single-brand-class {
  margin: 2px 12px;
  padding: 14px;
  width: 9%;
  height: 20%;
}

.home-page-single-brand-class {
  width: 100px; 
  height: auto;
  object-fit: contain; 
  margin: 10px; 
}

.dealing-brands-lto-icons-container {
  background-color: #e87a28;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -2%;
}

.dealing-brands-lto-text-col {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.dealing-brands-lto-text {
  color: gray;
  font-weight: 700;
}

.car-brands-lto-div {
  border-radius: 10px;
  padding: 10px 5px;
  color: #e87a28;
}

.single-brand-logo-lto {
  max-width: 80px;
}

.lto-calculator-container {
  padding: 10px 20px;
  border-radius: 15px;
  background-color: rgb(217, 217, 217);
}

.lto-calculator-input-group {
  position: relative;
}

.lto-calculator-input-group:after {
  position: absolute;
  right: 7px;
  top: 17px;
  content: "AED";
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

.lto-calculator-duration-box,
.lto-calculator-down-payment-box {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.lto-calculator-duration-slider,
.lto-calculator-down-payment-slider {
  color: #e87a28;
  margin: 0px 20px;
}
.lto-cal-duration-min-month,
.lto-cal-duration-max-month,
.lto-cal-down-payment-min-percentage,
.lto-cal-down-payment-max-percentage {
  color: rgb(168, 168, 168);
  font-weight: 600 !important;
  margin-top: 4px !important;
}

.leasing-detail-div {
  background-color: white;
  border-radius: 15px;
  padding: 30px 30px;
}

.lto-cal-leasing-detail,
.lto-cal-summary-heading {
  font-weight: 600;
}

.invalid-input {
  border-color: red !important;
}

.lto-own-summary {
  padding: 30px 60px 30px 60px;
}

.lto-service-pkg-table {
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 5px;
}

.lto-service-pkg-table-heading-1,
.lto-service-pkg-table-heading-2,
.lto-service-pkg-table-value {
  padding: 1rem;
}

.lto-service-pkg-table-heading-1,
.lto-service-pkg-table-heading-2 {
  background-color: #e87a28;
  color: white;
}

.lto-service-pkg-table-heading-1 {
  border-top-left-radius: 13px;
}
.lto-service-pkg-table-heading-2 {
  border-top-right-radius: 13px;
}

.lto-service-pkg-table-value {
  background-color: rgb(225, 225, 225);
  font-weight: 400;
}

.service-pkg-table-value-1.last-child {
  border-bottom-left-radius: 13px;
}

.service-pkg-table-value-2.last-child {
  border-bottom-right-radius: 13px;
}

.service-warranty-value-span,
.car-value-span {
  font-weight: 700;
}

.lto-lease-now-button {
  padding: 10px;
  border-radius: 10px;
  background-color: #e87a28;
  color: white;
  border: 1px solid #e87a28;
}

.lto-lease-now-button:hover {
  color: #e87a28;
  background-color: white;
  border: 1px solid #e87a28;
}

@media screen and (min-width: 1200px) {
  .single-brand-logo-lto {
    max-width: 100px;
  }
  .dealing-brands-lto-text {
    font-size: 40px;
  }
}

@media screen and (max-width: 991px) {
  .lto-cal-summary-heading {
    font-size: 20px;
  }
  .lto-cal-summary-label-text {
    font-size: 18px;
  }
  .lto-single-brand-class {
    margin: 10px;
    padding: 10px;
    width: 12%;
  }
}

@media screen and (max-width: 767px) {
  .br-tag-lg-screens {
    display: none;
  }
  .dealing-brands-lto-text-col {
    border: none;
  }
  .lto-single-brand-class {
    margin: 5px;
    padding: 10px;
    width: 15%;
  }
}

@media screen and (max-width: 576px) {
  .single-brand-logo-lto {
    max-width: 50px;
  }
  .dealing-brands-lto-icons-container {
    left: 0%;
  }
  .lto-own-summary {
    padding: 30px;
  }
  .lto-process-main-heading,
  .lto-calculator-main-heading,
  .lto-cal-summary-heading {
    font-size: 20px;
  }
  .lto-cal-summary-label-text {
    font-size: 18px;
  }
  .lto-cal-summary-value-text {
    font-size: 17px;
  }

  .lto-single-brand-class {
    margin: 1px;
    padding: 5px 10px;
    width: 20%;
  }
  .home-page-single-brand-class {
    margin: 1px;
    padding: 3px;
    width: 20%;
  }
}
