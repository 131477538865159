.instagram-feed-div {
  display: flex;
  flex-wrap: wrap;
}

.insta-feed-template-div {
  padding: 0px 5px !important;
  margin-bottom: -13%;
}

.insta-feed-template-div:hover {
  transform: scale(1.01);
  transition: 0.4s ease-in-out;
}

.instagram-feed-div .col-lg-3.col-md-4.col-sm-6.col-12 {
  padding: 5px !important;
}

.instagram-feed-div a img {
  width: 100%;
  height: 68%;
  object-fit: cover;
  border-radius: 12px;
}

@media screen and (max-width: 991px) {
  .insta-feed-template-div {
    padding: 0px 5px !important;
    margin-bottom: -17%;
  }

  .instagram-feed-div {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 576px) {
  .insta-feed-template-div {
    margin-bottom: -15%;
  }
}
